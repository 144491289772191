$(document).ajaxError(function (event, jqxhr, settings, thrownError) {
   if (jqxhr.status === 401) {
        document.location.href = '/';
   }
});



$(document).ready(function(){

    $('.counter').each(function () {
        var $this = $(this);
        jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
            duration: 1000,
            easing: 'swing',
            step: function () {
                $this.text(Math.ceil(this.Counter));
            }
        });
    });

});


window.initComponents = function() {
    $('[data-toggle="popover"]').popover({
            "trigger": "hover",
            "container": "body",
            "placement": "left",
            "html": true
    });

    $('[data-toggle="tooltip"]').tooltip({
        "trigger": "hover",
        "placement": "top",
        "delay": { show: 400, hide: 200 },
        "html": true,
        "container": 'body'
        });
}

window.dt_options = {

	"initComplete" : function(settings, data){
        initComponents();
    },

    "drawComplete" : function(settings, data){
        initComponents();
    } 
}